type Args = {
  email: string
  isEmailSingleUser: boolean
  companyCode?: string
  password: string
  token: string
}

type SuccessResult = {
  /**
   * response.url is different from response.obj.url
   */
  url: string
  hasError: boolean
  obj: {
    url?: string
  }
}

type ErrorResult = {
  hasError: true
  obj: null
  errorCode: number
  id: number
  hashID: null
  hasWarning: false
  successMessage: null
  sMessage: null
  warningMessage: ''
  wMessage: ''
  errorMessage: string
  eMessage: string
  redirectURL: null
  url: null
  isStatic: false
  operationType: number
  moduleName: null
  guid: string
}

const validateMfaToken = async (args: Args) => {
  const { email, isEmailSingleUser, companyCode, password, token } = args

  const urlCompanyCode = companyCode?.toLowerCase() || 'toast'
  const resp = await fetch(`/Login/${urlCompanyCode}/TFA`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username: email,
      password,
      token,
      isSingleUser: isEmailSingleUser
    })
  })

  if (resp.ok) {
    return (await resp.json()) as SuccessResult | ErrorResult
  } else {
    throw new Error('Unable to parse error response: ' + (await resp.text()))
  }
}

export default validateMfaToken
