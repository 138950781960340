import { useUserStatusMutation } from '../../data'
import { UsernameForm, UsernameFormData } from './UsernameForm'
import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useEcTitle } from '@toasttab/ec-title'
import { useResetPasswordDispatch } from '../ResetPasswordProvider'

export function Username() {
  const dispatch = useResetPasswordDispatch()
  const { t } = useTranslation('login')
  const { mutateAsync: userLinkingStatus, reset: resetUserLinkingStatus } =
    useUserStatusMutation()

  useEcTitle(t('username'))

  const onSubmit = useMemo(
    () => async (data: UsernameFormData) => {
      resetUserLinkingStatus()

      const username = data.username.trim()

      const { status, loginNonce } = await userLinkingStatus({
        email: username
      })

      if (!loginNonce) {
        throw new Error('Login nonce not found')
      }

      dispatch({
        type: 'username-entered',
        username: username,
        loginNonce: loginNonce,
        status: status
      })
    },
    [userLinkingStatus, resetUserLinkingStatus, dispatch]
  )

  return <UsernameForm onSubmit={onSubmit} />
}
