import * as React from 'react'
import { FormLayout } from '../components'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

export const InProgress = (props: {
  title: string
  subtitle?: string | null
}) => {
  const { title, subtitle } = props

  return (
    <FormLayout title={title} subtitle={subtitle}>
      <div className='flex flex-row justify-center pt-4'>
        <MerryGoRound />
      </div>
    </FormLayout>
  )
}
