import * as React from 'react'
import {
  ResetPasswordState,
  WaitForCompanyCode,
  WaitForUsername
} from './state'
import { ResetPasswordAction } from './actions'

function waitForUsername(
  state: WaitForUsername,
  action: ResetPasswordAction
): ResetPasswordState {
  switch (action.type) {
    case 'username-entered':
      switch (action.status) {
        case 'SINGLE_USER':
          return {
            type: 'reset-password-with-payroll',
            username: action.username
          }
        case 'SINGLE_USER_LINKED':
          return {
            type: 'reset-password-with-toast',
            username: action.username,
            loginNonce: action.loginNonce
          }
        case 'MULTIPLE_USERS_LINKED':
        case 'MULTIPLE_USERS':
          return {
            type: 'wait-for-company-code',
            username: action.username
          }
      }
  }
  throw new Error(`Invalid action ${action.type} for state ${state.type}`)
}

function waitForCompanyCode(
  state: WaitForCompanyCode,
  action: ResetPasswordAction
): ResetPasswordState {
  switch (action.type) {
    case 'username-entered':
      switch (action.status) {
        case 'SINGLE_USER':
          return {
            type: 'reset-password-with-payroll',
            username: action.username
          }
        case 'MULTIPLE_USERS':
          return {
            type: 'wait-for-company-code',
            username: action.username
          }
        case 'MULTIPLE_USERS_LINKED':
        case 'SINGLE_USER_LINKED':
          return {
            type: 'reset-password-with-toast',
            username: action.username,
            loginNonce: action.loginNonce
          }
      }
  }
  throw new Error(`Invalid action ${action.type} for state ${state.type}`)
}

export function resetPasswordReducer(
  state: ResetPasswordState,
  action: ResetPasswordAction
): ResetPasswordState {
  switch (state.type) {
    case 'wait-for-username':
      return waitForUsername(state, action)
    case 'wait-for-company-code':
      return waitForCompanyCode(state, action)
  }
  throw new Error(`Invalid action ${action.type} for state ${state.type}`)
}

export type ResetPasswordDispatch = React.Dispatch<ResetPasswordAction>
