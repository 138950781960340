import * as React from 'react'
import { useEffect } from 'react'
import { useStableCallback } from './useStableCallback'
import { getReCaptchaV3Script } from './reCaptchaHelpers'
import { V2_SITE_KEY } from './recaptcha-v2-site-key'

declare global {
  interface Window {
    grecaptcha?: {
      reset: () => void
    }
  }
}

type AnyFunc = (...args: any[]) => any

const useGlobalCallback = (name: string, fn: AnyFunc) => {
  const stableFn = useStableCallback(fn)

  const fullName = `onEcLoginRecaptchaEvent${name}`

  useEffect(() => {
    // @ts-ignore
    window[fullName] = stableFn

    return () => {
      // @ts-ignore
      window[fullName] = undefined
    }
  }, [fullName, stableFn])

  return fullName
}

type Token = string | null

type Props = {
  onToken: (token: Token) => void
}

const ReCaptcha = (props: Props) => {
  const { onToken } = props

  useEffect(() => {
    const script = getReCaptchaV3Script()

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const tokenCallbackStr = useGlobalCallback('onToken', onToken)

  const expiredCallbackStr = useGlobalCallback('onExpired', () => {
    onToken(null)
    setTimeout(() => window.grecaptcha?.reset(), 0)
  })

  return (
    <div className='flex flex-row justify-center pt-4'>
      <div
        data-testid='recaptcha'
        className='g-recaptcha transform: scale(0.77); -webkit-transform: scale(0.77); transform-origin: 0 0; -webkit-transform-origin: 0 0;'
        data-sitekey={V2_SITE_KEY}
        data-callback={tokenCallbackStr}
        data-expired-callback={expiredCallbackStr}
      />
    </div>
  )
}

export { ReCaptcha }
