import { useResetPasswordMutation } from '../data'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { InProgress } from '../SignIn/InProgress'
import {
  ActionsBar,
  FormLayout,
  PasswordResetEmailSuccessMessage
} from '../components'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useTranslation } from 'react-i18next'

export const ResetPasswordWithPayroll = (props: {
  username: string
  companyCode?: string
}) => {
  const { username, companyCode } = props
  const [success, setSuccess] = useState(false)
  const { t } = useTranslation('login')

  const { mutateAsync: resetPassword } = useResetPasswordMutation({
    onSuccess: () => {
      setSuccess(true)
    }
  })

  useEffect(() => {
    resetPassword({
      email: username,
      companyCode
    })
  }, [])

  if (success) {
    return (
      <FormLayout
        title={t('passwordReset')}
        successMessage={<PasswordResetEmailSuccessMessage />}
      >
        <ActionsBar>
          <Button className='self-end' id='next' as='a' href={'/sign-in'}>
            {t('signIn')}
          </Button>
        </ActionsBar>
      </FormLayout>
    )
  } else {
    return <InProgress title={t('resettingPassword')} />
  }
}
