import { Input } from './index'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export function PasswordInput(props: {
  password?: string
  autoFocus: boolean
  disabled: boolean
}) {
  const { password, autoFocus, disabled } = props
  const { t } = useTranslation('login')

  return (
    <Input
      type='password'
      name='password'
      label={t('password')}
      autoFocus={autoFocus}
      disabled={disabled}
      value={password}
    />
  )
}

PasswordInput.defaultProps = {
  autoFocus: false,
  disabled: false
}
