import * as React from 'react'
import { Username } from './username/Username'
import { CompanyCode } from './companyCode/CompanyCode'
import { getSavedCompanyCode } from '../helpers'
import { ResetPasswordWithToast } from './ResetPasswordWithToast'
import { ResetPasswordWithPayroll } from './ResetPasswordWithPayroll'
import { useResetPasswordState } from './ResetPasswordProvider'

export function ResetPassword() {
  const state = useResetPasswordState()
  const savedCompanyCode = getSavedCompanyCode()

  switch (state.type) {
    case 'wait-for-username':
      return <Username />
    case 'wait-for-company-code':
      return <CompanyCode {...state} companyCode={savedCompanyCode} />
    case 'reset-password-with-toast':
      return <ResetPasswordWithToast {...state} />
    case 'reset-password-with-payroll':
      return <ResetPasswordWithPayroll {...state} />
  }
}
