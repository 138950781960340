import * as React from 'react'
import { CompanyCode } from './companyCode/CompanyCode'
import { AuthenticateWithToast } from './AuthenticateWithToast'
import { Password } from './password/Password'
import { MfaToken } from './mfaToken/MfaToken'
import { Username } from './username/Username'
import { LoginInProgress } from '../session/LoginInProgress'
import { ResetPasswordForm } from './resetPassword/ResetPasswordForm'
import { useLoginState } from './LoginProvider'
import { ResetPasswordApp } from '../../ResetPassword/ResetPasswordApp'

export const Login = () => {
  const state = useLoginState()

  switch (state.type) {
    case 'wait-for-username':
      return <Username {...state} />
    case 'wait-for-company-code':
      return <CompanyCode {...state} />
    case 'wait-for-password':
      return <Password {...state} />
    case 'wait-for-mfa-token':
      return <MfaToken {...state} />
    case 'wait-for-password-reset':
      return <ResetPasswordForm {...state} />
    case 'authenticate-with-toast':
      return <AuthenticateWithToast {...state} />
    case 'login-in-progress':
      return <LoginInProgress {...state} />
    case 'reset-password':
      return <ResetPasswordApp {...state} />
  }
}
