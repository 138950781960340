import { useEffect } from 'react'
import { useRedirectToAuth0 } from '../hooks'
import { InProgress } from '../SignIn/InProgress'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
export const ResetPasswordWithToast = (props: {
  username: string
  companyCode?: string
  loginNonce: string
}) => {
  const { username, companyCode, loginNonce } = props
  const { t } = useTranslation('login')
  const { redirectToAuth0 } = useRedirectToAuth0()

  useEffect(() => {
    redirectToAuth0({ email: username, companyCode, loginNonce })
  }, [redirectToAuth0, username, companyCode, loginNonce])

  return (
    <InProgress
      title={t('resetPassword')}
      subtitle={t('resetPasswordWithToast')}
    />
  )
}
