import * as React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { LanguageServiceProvider } from '@local/services'

const queryClient = new QueryClient()

const SystemProviders = ({ children }: React.PropsWithChildren) => {
  return (
    <LanguageServiceProvider>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </LanguageServiceProvider>
  )
}

export default SystemProviders
