import {
  LoginState,
  WaitForCompanyCode,
  WaitForMfaToken,
  WaitForPassword,
  WaitForResetPassword,
  WaitForUsername
} from './state'
import { LoginAction } from './actions'
import * as React from 'react'

function waitForUsername(
  state: WaitForUsername,
  action: LoginAction
): LoginState {
  switch (action.type) {
    case 'username-entered':
      switch (action.status) {
        case 'SINGLE_USER':
          return {
            type: 'wait-for-password',
            username: action.username,
            isCaptchaRequired: action.isCaptchaRequired,
            returnUrl: state.returnUrl,
            isReauth: state.isReauth
          }
        case 'MULTIPLE_USERS_LINKED':
          return {
            type: 'wait-for-company-code',
            username: action.username,
            companyCode: state.companyCode,
            returnUrl: state.returnUrl,
            isCaptchaRequired: action.isCaptchaRequired,
            isReauth: state.isReauth
          }
        case 'SINGLE_USER_LINKED':
          return {
            type: 'authenticate-with-toast',
            username: action.username,
            loginNonce: action.loginNonce,
            returnUrl: state.returnUrl,
            isReauth: state.isReauth
          }
        case 'MULTIPLE_USERS':
          return {
            type: 'wait-for-company-code',
            username: action.username,
            companyCode: state.companyCode,
            returnUrl: state.returnUrl,
            isCaptchaRequired: action.isCaptchaRequired,
            isReauth: state.isReauth
          }
      }
  }
  throw new Error(`Invalid action ${action.type} for state ${state.type}`)
}

function waitForCompanyCode(
  state: WaitForCompanyCode,
  action: LoginAction
): LoginState {
  switch (action.type) {
    case 'back':
      return {
        type: 'wait-for-username',
        username: state.username,
        companyCode: state.companyCode,
        returnUrl: state.returnUrl,
        isReauth: state.isReauth
      }
    case 'company-code-entered':
      switch (action.status) {
        case 'SINGLE_USER':
          return {
            type: 'wait-for-password',
            username: state.username,
            companyCode: action.companyCode,
            isCaptchaRequired: action.isCaptchaRequired,
            returnUrl: state.returnUrl,
            isReauth: state.isReauth
          }
        case 'MULTIPLE_USERS':
          return {
            type: 'wait-for-company-code',
            username: state.username,
            companyCode: action.companyCode,
            isCaptchaRequired: action.isCaptchaRequired,
            returnUrl: state.returnUrl,
            isReauth: state.isReauth
          }
        case 'MULTIPLE_USERS_LINKED':
        case 'SINGLE_USER_LINKED':
          return {
            type: 'authenticate-with-toast',
            username: state.username,
            loginNonce: action.loginNonce,
            returnUrl: state.returnUrl,
            isReauth: state.isReauth
          }
      }
  }
  throw new Error(`Invalid action ${action.type} for state ${state.type}`)
}

function waitForPassword(
  state: WaitForPassword,
  action: LoginAction
): LoginState {
  switch (action.type) {
    case 'back':
      if (state.companyCode) {
        return {
          type: 'wait-for-company-code',
          username: state.username,
          companyCode: state.companyCode,
          returnUrl: state.returnUrl,
          isCaptchaRequired: state.isCaptchaRequired,
          isReauth: state.isReauth
        }
      } else {
        return {
          type: 'wait-for-username',
          username: state.username,
          companyCode: state.companyCode,
          returnUrl: state.returnUrl,
          isReauth: state.isReauth
        }
      }
    case 'password-validated':
      return {
        type: 'login-in-progress',
        url: state.returnUrl || action.url || '/'
      }
    case 'reset-password':
      return {
        type: 'wait-for-password-reset',
        username: state.username,
        companyCode: state.companyCode,
        returnUrl: state.returnUrl,
        isCaptchaRequired: state.isCaptchaRequired,
        isReauth: state.isReauth
      }
    case 'mfa-required':
      return {
        type: 'wait-for-mfa-token',
        username: state.username,
        companyCode: state.companyCode,
        password: action.password,
        returnUrl: state.returnUrl,
        isCaptchaRequired: state.isCaptchaRequired,
        isReauth: state.isReauth
      }
  }
  throw new Error(`Invalid action ${action.type} for state ${state.type}`)
}

function waitForMfaToken(
  state: WaitForMfaToken,
  action: LoginAction
): LoginState {
  switch (action.type) {
    case 'mfa-token-validated':
      return {
        type: 'login-in-progress',
        url: state.returnUrl || action.url || '/'
      }
    case 'back':
      return {
        type: 'wait-for-password',
        username: state.username,
        companyCode: state.companyCode,
        returnUrl: state.returnUrl,
        isCaptchaRequired: state.isCaptchaRequired,
        isReauth: state.isReauth
      }
  }
  throw new Error(`Invalid action ${action.type} for state ${state.type}`)
}

function waitForResetPassword(
  state: WaitForResetPassword,
  action: LoginAction
): LoginState {
  switch (action.type) {
    case 'back':
      if (state.companyCode === undefined) {
        return {
          type: 'wait-for-username',
          username: state.username,
          returnUrl: state.returnUrl,
          isReauth: state.isReauth
        }
      } else {
        return {
          type: 'wait-for-password',
          username: state.username,
          companyCode: state.companyCode,
          returnUrl: state.returnUrl,
          isReauth: state.isReauth,
          isCaptchaRequired: state.isCaptchaRequired
        }
      }
    case 'reset-password':
      return {
        type: 'reset-password',
        username: state.username,
        companyCode: state.companyCode
      }
  }
  throw new Error(`Invalid action ${action.type} for state ${state.type}`)
}

export function loginReducer(
  state: LoginState,
  action: LoginAction
): LoginState {
  switch (state.type) {
    case 'wait-for-password-reset':
      return waitForResetPassword(state, action)
    case 'wait-for-username':
      return waitForUsername(state, action)
    case 'wait-for-company-code':
      return waitForCompanyCode(state, action)
    case 'wait-for-password':
      return waitForPassword(state, action)
    case 'wait-for-mfa-token':
      return waitForMfaToken(state, action)
  }
  throw new Error(`Invalid action ${action.type} for state ${state.type}`)
}

export type LoginDispatch = React.Dispatch<LoginAction>
