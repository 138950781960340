import * as React from 'react'
import { useTranslation } from 'react-i18next'

const PasswordResetEmailSuccessMessage = () => {
  const { t } = useTranslation('login')

  return (
    <>
      <b>{t('resetEmailSent')}</b>
      <div>{t('resetEmailInstructions')}</div>
    </>
  )
}

export default PasswordResetEmailSuccessMessage
