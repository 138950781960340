import * as React from 'react'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'

import { domElementGetter } from '@toasttab/ec-layout'

import { App } from './app/App'
import { ErrorPage } from './app/components/errors/AppErrorBoundary'
import './index.css'

/**
 * This is the only app that is truly full width.
 *
 * 'full-width' is a class that is provided by ec-spa.css in esx-web
 */
document.body.classList.add('full-width')

const reactLifecycles = banquetSingleSpaReact({
  singleSpaCssLifecycles,
  cssScope: 'data-ec-login',
  rootComponent: App,
  portalContainers: ['banquetPortalsContainer'],
  domElementGetter: domElementGetter.application,
  errorBoundary: (error: Error) => (
    <ErrorPage error={error} resetErrorBoundary={window.location.reload} />
  ),
  suppressComponentDidCatchWarning: true
  // sentry: {
  //   publicKey: '9e2064d7f70940c2a7f5e406d3c3a5aa@o37442',
  //   projectId: '4505477799870464',
  //   releaseVersion: process.env.PKG_VERSION
  // }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'ec-simplified-login-spa'

//this will automatically be done by banquetSingleSpaReact at some point.
sessionStorage.setItem(
  `sentry:${name}`,
  `https://9e2064d7f70940c2a7f5e406d3c3a5aa@o37442.ingest.sentry.io/4505477799870464`
)
