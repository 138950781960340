import * as React from 'react'
import { SessionContextProvider } from './session/SessionProvider'
import { SignIn } from './SignIn'
import { useEcBanquetProps } from '@toasttab/ec-banquet-props'
import { useReducer } from 'react'
import { sessionReducer } from './session/reducer'
import { InitialState } from './session/state'
import { useSignInSearchParams } from '../hooks'

export const SignInApp = () => {
  const { token } = useEcBanquetProps()
  const { returnUrl, isReauth } = useSignInSearchParams()

  const toastUserToken = token && !isReauth ? token : undefined

  const [state, dispatch] = useReducer(sessionReducer, {
    type: 'initial-state',
    toastUserToken: toastUserToken,
    returnUrl: returnUrl,
    isReauth: isReauth
  } as InitialState)

  return (
    <SessionContextProvider state={state} dispatch={dispatch}>
      <SignIn />
    </SessionContextProvider>
  )
}
