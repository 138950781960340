import { useApolloClient } from '@apollo/client'
import { UserLinkingStatusDocument } from '@local/api/generated/gql/graphql'
import { useMutation } from '@tanstack/react-query'

type Variables = {
  email: string
  companyCode: string
}

const useUserCompanyStatusMutation = () => {
  const apolloClient = useApolloClient()

  return useMutation({
    mutationFn: async (variables: Variables) => {
      const { email, companyCode } = variables

      const query = await apolloClient.query({
        query: UserLinkingStatusDocument,
        variables: {
          input: {
            email,
            companyCode
          }
        },
        fetchPolicy: 'no-cache'
      })

      const resp = query.data.userLinkingStatus

      const status = resp.status
      const loginNonce = resp.loginNonce
      const isCaptchaRequired = !!resp.isCaptchaRequired || false

      return {
        status,
        loginNonce,
        isCaptchaRequired
      }
    }
  })
}

export default useUserCompanyStatusMutation
