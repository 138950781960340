const BLOCKLIST = [
  '',
  '/',
  // redirecting to a logout URL after logging in will be self defeating for the user
  '/logout.aspx',
  'logout.aspx',
  '/logout',
  'logout',
  '/toast/dashboard',
  '/mvc/toast/dashboard'
]

const isBlockedReturnUrl = (str: string | undefined) => {
  if (typeof str !== 'string') {
    return false
  }
  return BLOCKLIST.includes(str.toLowerCase())
}

export { isBlockedReturnUrl }
