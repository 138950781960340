const isSignInUrl = (url: string): boolean =>
  url.toLowerCase().indexOf('/sign-in') !== -1

export const authenticateWithToastUserToken = async (
  token: string,
  isReauth: boolean,
  returnUrl?: string
): Promise<string | undefined> => {
  try {
    const response = await fetch(`/Login/Index`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token,
        isReauth,
        returnUrl
      })
    })
    if (response.redirected) {
      if (!isSignInUrl(response.url)) {
        return response.url
      }
    } else if (response.ok) {
      return response.url
    }
  } catch (e) {}
}
