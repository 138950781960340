import * as React from 'react'
import { TextInputField } from '@toasttab/buffet-pui-forms'
import { TextInput } from '@toasttab/buffet-pui-text-input'
import { useFormikContext } from 'formik'

type Props = {
  name: string
  label?: string | null
  errorText?: string
  disabled?: boolean
  value?: string
  type?: 'password'
  autoFocus?: boolean
  onChange?: (str: string) => void
}

const getFormikValue = (formik: any, name: string) => {
  if (!name) return

  try {
    return formik.values[name]
  } catch {
    return undefined
  }
}

const getDisplayError = (formik: any, name: string) => {
  if (!name) return

  try {
    const hasSubmitted = !!formik.submitCount
    const error = formik.errors?.[name]

    if (hasSubmitted && error && typeof error === 'string') {
      return error
    }
  } catch {
    return undefined
  }
}

const Input = (props: Props) => {
  const { name, errorText, label, disabled, value, type, onChange, ...rest } =
    props

  const displayLabel = label || name

  const formik = useFormikContext()

  const contextError = getDisplayError(formik, name)

  const formikValue = getFormikValue(formik, name)

  return (
    <div className='pt-4'>
      {disabled ? (
        <TextInput
          {...rest}
          type={type}
          disabled
          name={name}
          label={displayLabel}
          value={value || formikValue || ''}
          errorText={errorText || contextError}
        />
      ) : (
        <TextInputField
          {...rest}
          type={type}
          label={displayLabel}
          autoComplete={name}
          id={name}
          name={name}
          testId={name}
          errorText={errorText || contextError}
          onChange={(event) => {
            const value = event.target.value
            // when onChange is passed to `TextInputField`,
            // it appears to disable the formik update call
            // so we manually update it here
            formik.setFieldValue(name, value)

            if (onChange) onChange(value)
          }}
        />
      )}
    </div>
  )
}

export default Input
