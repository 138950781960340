import { useSessionState } from './session/SessionProvider'
import { GetPayrollSession } from './session/GetPayrollSession'
import { PayrollSessionExists } from './session/PayrollSessionExists'
import { AuthenticateToastUserToken } from './session/AuthenticateToastUserToken'
import { LoggingOut } from './session/LoggingOut'
import { LoginInProgress } from './session/LoginInProgress'
import * as React from 'react'
import { LoginApp } from './login/LoginApp'

export const SignIn = () => {
  const state = useSessionState()

  switch (state.type) {
    case 'initial-state':
      return <GetPayrollSession />
    case 'payroll-session-exists':
      return <PayrollSessionExists {...state} />
    case 'authenticate-toast-user-token':
      return <AuthenticateToastUserToken {...state} />
    case 'logging-out':
      return <LoggingOut />
    case 'login-in-progress':
      return <LoginInProgress {...state} />
    case 'login':
      return <LoginApp {...state} />
  }
}
