import { Input } from './index'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export function UsernameInput(props: {
  username?: string
  autoFocus: boolean
  disabled: boolean
}) {
  const { username, autoFocus, disabled } = props
  const { t } = useTranslation('login')

  return (
    <Input
      label={t('emailUsername')}
      name='username'
      data-testid='username'
      autoFocus={autoFocus}
      disabled={disabled}
      value={username}
    />
  )
}

UsernameInput.defaultProps = {
  autoFocus: false,
  disabled: false
}
