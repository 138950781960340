import { useTranslation } from 'react-i18next'
import { useEcTitle } from '@toasttab/ec-title'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { PasswordForm, PasswordFormData } from './PasswordForm'
import { useLoginValidateMutation } from '../../../data'
import { useLoginDispatch } from '../LoginProvider'

export const Password = (props: {
  username: string
  companyCode?: string
  isCaptchaRequired: boolean
}) => {
  const dispatch = useLoginDispatch()
  const { username, companyCode, isCaptchaRequired } = props
  const { t } = useTranslation('login')
  const [error, setError] = useState<string | undefined | null>(undefined)

  useEcTitle(t('password'))

  const { mutateAsync: validateCredentials, reset: resetValidateCredentials } =
    useLoginValidateMutation()

  const onSubmit = useMemo(
    () => async (data: PasswordFormData) => {
      setError(undefined)
      resetValidateCredentials()

      const { type, url } = await validateCredentials({
        email: username,
        isEmailSingleUser: companyCode === undefined,
        companyCode,
        password: data.password,
        reCaptchaToken: data.reCaptchaToken
      })

      switch (type) {
        case 'success':
          dispatch({
            type: 'password-validated',
            url: url
          })
          break
        case 'token-required':
          dispatch({
            type: 'mfa-required',
            password: data.password
          })
          break
        case 'force-reset-password':
          setError(t('forceResetPasswordInstructions'))
          break
        case 'password-expired':
          setError(t('forceResetPasswordInstructions'))
          break
        case 'unauthorized':
          setError(t('error.unauthorized'))
          break
        case 'error':
          setError(t('error.generic'))
          break
        case 'ip-address-restriction':
          setError(t('error.ipAddressRestriction'))
          break
        case 'rate-limit':
          setError(t('error.rateLimit'))
          break
      }
    },
    [
      username,
      companyCode,
      dispatch,
      validateCredentials,
      resetValidateCredentials
    ]
  )

  return (
    <PasswordForm
      dispatch={dispatch}
      username={username}
      companyCode={companyCode}
      isCaptchaRequired={isCaptchaRequired}
      onSubmit={onSubmit}
      error={error ? error : undefined}
    />
  )
}
