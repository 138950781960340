import { useApolloClient } from '@apollo/client'
import { ResetPasswordDocument } from '@local/api/generated/gql/graphql'
import isEmpty from 'lodash/isEmpty'
import { useMutation } from '@tanstack/react-query'

type Variables = {
  email: string
  companyCode?: string
}

type Args = {
  onSuccess?: () => void
}
const useResetPasswordMutation = (args?: Args) => {
  const apolloClient = useApolloClient()

  return useMutation({
    mutationFn: async (variables: Variables) => {
      const { email, companyCode } = variables

      const result = await apolloClient.mutate({
        mutation: ResetPasswordDocument,
        variables: {
          input: {
            email,
            companyCode
          }
        },
        fetchPolicy: 'no-cache'
      })

      if (result.data?.resetPassword === true) {
        return result.data
      }

      if (!result || !isEmpty(result.errors)) {
        throw new Error(
          `Error occurred when resetting password: ${JSON.stringify(
            result.errors
          )}`
        )
      }
      throw new Error('Unsuccessful password reset')
    },

    onSuccess: () => {
      if (args?.onSuccess) {
        args.onSuccess()
      }
    }
  })
}

export default useResetPasswordMutation
