import { ActionsBar } from '../../components'
import { BackButton } from './BackButton'
import { NextSubmitButton } from '../../components/NextSubmitButton'
import * as React from 'react'

export function FormActionsBar(props: {
  showBackButton: boolean
  isInProgress: boolean
}) {
  const { showBackButton, isInProgress } = props
  return (
    <ActionsBar>
      {showBackButton && <BackButton disabled={isInProgress} />}
      <NextSubmitButton mode={isInProgress ? 'in-progress' : 'ready'} />
    </ActionsBar>
  )
}

FormActionsBar.defaultProps = {
  showBackButton: false
}
