import { useState, useEffect } from 'react'
import throttle from 'lodash/throttle'

const getWindowSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(() => getWindowSize())

  useEffect(() => {
    const onResize = throttle(() => {
      setWindowSize((prev) => {
        const size = getWindowSize()
        const changed = prev.width !== size.width || prev.height !== size.height
        return changed ? size : prev
      })
    }, 100)

    const id = setInterval(onResize, 5000)

    window.addEventListener('resize', onResize)

    return () => {
      clearInterval(id)
      window.removeEventListener('resize', onResize)
    }
  }, [])

  useEffect(() => {})

  return windowSize
}

export default useWindowSize
