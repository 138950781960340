import { useMutation } from '@tanstack/react-query'

import { validateMfaToken } from '../helpers'

type Variables = {
  email: string
  isEmailSingleUser: boolean
  password: string
  companyCode?: string
  token: string
}

const useValidateMfaTokenMutation = () => {
  const mutation = useMutation({
    mutationFn: async (variables: Variables) => {
      const { email, isEmailSingleUser, password, companyCode, token } =
        variables

      return validateMfaToken({
        email,
        isEmailSingleUser,
        companyCode,
        password,
        token
      })
    }
  })

  return mutation
}

export default useValidateMfaTokenMutation
