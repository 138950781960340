import * as React from 'react'
import { useState } from 'react'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { Button } from '@toasttab/buffet-pui-buttons'
import { TestIdentifiable } from '@toasttab/buffet-shared-types'

type Props = TestIdentifiable & {
  linkText: string
  infoContent: React.ReactNode
  trackId: string
}

const InfoAlertLink = (props: Props) => {
  const { linkText, infoContent } = props

  const [isOpen, setIsOpen] = useState<boolean>(false)

  return isOpen ? (
    <div className='py-2'>
      <Alert
        variant='info'
        className='w-full'
        onDismiss={() => setIsOpen(false)}
      >
        <div className='whitespace-pre-wrap'>{infoContent}</div>
      </Alert>
    </div>
  ) : (
    <Button
      testId={props.testId}
      data-toast-track-id={props.trackId}
      type='button'
      variant='text-link'
      className='inline-link'
      onClick={() => setIsOpen(true)}
    >
      {linkText}
    </Button>
  )
}

export default InfoAlertLink
