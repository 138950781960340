import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { authenticateWithToastUserToken } from '../../helpers'
import { InProgress } from '../InProgress'
import { useTranslation } from 'react-i18next'
import { useSessionDispatch } from './SessionProvider'

export const AuthenticateToastUserToken = (props: {
  toastUserToken: string
  isReauth: boolean
  returnUrl?: string
}) => {
  const dispatch = useSessionDispatch()
  const { t } = useTranslation('login')
  const { returnUrl, toastUserToken, isReauth } = props

  const logInWithToastToken = useMemo(
    () => async () => {
      const url = await authenticateWithToastUserToken(toastUserToken, isReauth, returnUrl)
      if (url === undefined) {
        dispatch({
          type: 'toast-user-token-authentication-failed'
        })
      } else {
        dispatch({
          type: 'toast-user-token-authentication-succeeded',
          url: returnUrl || url
        })
      }
    },
    [dispatch, toastUserToken, isReauth, authenticateWithToastUserToken]
  )

  useEffect(() => {
    logInWithToastToken()
  }, [logInWithToastToken])

  return (
    <InProgress title={t('welcome')} subtitle={t('checkingExistingSessions')} />
  )
}
