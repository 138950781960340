import { useRef, useEffect, useState } from 'react'

type AnyFunc = (...args: any[]) => any

const useStableCallback = <T extends AnyFunc>(fn: T): T => {
  const ref = useRef(fn)

  useEffect(() => {
    ref.current = fn
  }, [fn])

  const [stableFn] = useState(() => {
    return ((...args: any[]) => ref.current(...args)) as T
  })

  return stableFn
}

export { useStableCallback }
