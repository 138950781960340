import { SubmitButton } from '@toasttab/buffet-pui-forms'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { PropsWithChildren } from 'react'

type NextButtonMode = 'in-progress' | 'disabled' | 'ready'

export const NextSubmitButton = (
  props: {
    mode: NextButtonMode
  } & PropsWithChildren
) => {
  const { children, mode } = props
  const { t } = useTranslation('login')

  return (
    <SubmitButton
      className='self-end'
      id='next'
      type='submit'
      testId='next'
      data-toast-track-id='next'
      isInProgress={mode === 'in-progress'}
      disabled={mode === 'disabled'}
    >
      {children || t('next')}
    </SubmitButton>
  )
}

NextSubmitButton.defaultProps = {
  mode: 'ready'
}
