const BASE_URL = window.location.origin

type Args = {
  email: string
  loginNonce: string
  companyCode?: string
  auth0ClientId: string
  auth0BaseUrl: string
  returnUrl?: string
  isReauth?: boolean
}

const createAuth0Url = (args: Args) => {
  const {
    email,
    loginNonce,
    companyCode,
    auth0ClientId,
    auth0BaseUrl,
    returnUrl,
    isReauth
  } = args

  if (email === undefined) {
    throw new Error('email is required')
  }

  const queryString = new URLSearchParams({
    scope: 'openid profile',
    client_id: auth0ClientId,
    response_type: 'code',
    audience: 'https://toast-users-api/',
    state: window.btoa(
      JSON.stringify({
        email,
        companyCode,
        returnUrl,
        loginNonce,
        isReauth
      })
    ),
    redirect_uri: `${BASE_URL}/login/callback-simplified`,
    login_hint: email
  })

  // Adding max_age=0 to the request requires the authentication provider to actively attempt to reauthenticate the user
  // See https://auth0.com/docs/authenticate/login/max-age-reauthentication#max_age-authentication-request-parameter for more details on the max_age parameter
  if (isReauth) {
    queryString.append('max_age', '0')
  }

  return `${auth0BaseUrl}/authorize?${queryString.toString()}`
}

export default createAuth0Url
