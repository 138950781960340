import * as React from 'react'
import redirectTo from '../../helpers/redirectTo'
import { InProgress } from '../InProgress'
import { useTranslation } from 'react-i18next'

export const LoginInProgress = (props: { url: string }) => {
  redirectTo(props.url)
  const { t } = useTranslation('login')

  return <InProgress title={t('loggingIn')} />
}
