import * as React from 'react'
import { PropsWithChildren, useReducer } from 'react'
import { ResetPasswordState } from './state'
import { ResetPasswordDispatch, resetPasswordReducer } from './reducer'

const ResetPasswordStateContext = React.createContext<
  ResetPasswordState | undefined
>(undefined)

export const ResetPasswordDispatchContext = React.createContext<
  ResetPasswordDispatch | undefined
>(undefined)

function getInitialState(
  username?: string,
  companyCode?: string
): ResetPasswordState {
  if (username === undefined) {
    return {
      type: 'wait-for-username'
    }
  } else {
    return {
      type: 'reset-password-with-payroll',
      username: username,
      companyCode: companyCode
    }
  }
}

export function ResetPasswordContextProvider(
  props: { username?: string; companyCode?: string } & PropsWithChildren
) {
  const { username, companyCode, children } = props
  const initialState = getInitialState(username, companyCode)
  const [state, dispatch] = useReducer(resetPasswordReducer, initialState)

  return (
    <ResetPasswordStateContext.Provider value={state}>
      <ResetPasswordDispatchContext.Provider value={dispatch}>
        {children}
      </ResetPasswordDispatchContext.Provider>
    </ResetPasswordStateContext.Provider>
  )
}

export function useResetPasswordState<T extends ResetPasswordState>(): T {
  const state = React.useContext(ResetPasswordStateContext)
  if (state === undefined) {
    throw new Error(
      'useResetPasswordState must be used within a ResetPasswordContextProvider'
    )
  }
  return state as T
}

export function useResetPasswordDispatch(): ResetPasswordDispatch {
  const dispatch = React.useContext(ResetPasswordDispatchContext)
  if (dispatch === undefined) {
    throw new Error(
      'useResetPasswordDispatch must be used within a ResetPasswordContextProvider'
    )
  }
  return dispatch
}
