import * as React from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import {
  Error500DesktopIllustration,
  Error500MobileIllustration
} from '@toasttab/buffet-pui-illustrations'
import { Button } from '@toasttab/buffet-pui-buttons'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'

export const ErrorPage = (props: FallbackProps) => {
  React.useLayoutEffect(() => {
    document.body.classList.add('error')
    return () => document.body.classList.remove('error')
  })

  const size = useScreenSize()

  return (
    <div
      role='alert'
      className='flex flex-col items-center h-full justify-center space-y-3 px-6'
    >
      {size < ScreenSize.MD ? (
        <Error500MobileIllustration />
      ) : (
        <Error500DesktopIllustration />
      )}

      <p className='font-semibold text-center'>Hmm, something went wrong</p>
      <p className='text-center'>{props.error.message}</p>
      <Button
        className='mt-6'
        variant='secondary'
        onClick={props.resetErrorBoundary}
      >
        Reload
      </Button>
    </div>
  )
}

export function AppErrorBoundary(props: React.PropsWithChildren<{}>) {
  return (
    <ErrorBoundary
      fallbackRender={(props) => <ErrorPage {...props} />}
      onReset={() => {
        window.location.reload()
      }}
    >
      {props.children}
    </ErrorBoundary>
  )
}
