import { Button } from '@toasttab/buffet-pui-buttons'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useResetPasswordDispatch } from './ResetPasswordProvider'

export function BackButton(props: { disabled: boolean }) {
  const dispatch = useResetPasswordDispatch()
  const { disabled } = props
  const { t } = useTranslation('login')

  return (
    <Button
      testId='back'
      variant='text-link'
      onClick={() => {
        dispatch({ type: 'back' })
      }}
      disabled={disabled}
    >
      {t('back')}
    </Button>
  )
}

BackButton.defaultProps = {
  disabled: false
}
