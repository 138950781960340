import { useTranslation } from 'react-i18next'
import { Button } from '@toasttab/buffet-pui-buttons'
import * as React from 'react'

export function ResetPasswordButton(props: { onClick: () => void }) {
  const { t } = useTranslation('login')
  const { onClick } = props

  return (
    <Button
      type='button'
      variant='text-link'
      className='inline-link'
      testId='forgot-password'
      data-toast-track-id='forgot-password'
      onClick={onClick}
    >
      {t('dontRememberYourPassword')}
    </Button>
  )
}
