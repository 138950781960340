import { useRedirectToAuth0 } from '../../hooks'
import * as React from 'react'
import { useEffect } from 'react'
import { InProgress } from '../InProgress'
import { useTranslation } from 'react-i18next'

export const AuthenticateWithToast = (props: {
  username: string
  companyCode?: string
  loginNonce: string
  returnUrl?: string
  isReauth?: boolean
}) => {
  const { username, companyCode, loginNonce, returnUrl, isReauth } = props
  const { redirectToAuth0 } = useRedirectToAuth0()
  const { t } = useTranslation('login')

  useEffect(() => {
    redirectToAuth0({
      email: username,
      companyCode,
      loginNonce,
      returnUrl,
      isReauth
    })
  }, [redirectToAuth0, username, companyCode, loginNonce, returnUrl, isReauth])

  return <InProgress title={t('loggingIn')} subtitle={t('loggingInViaToast')} />
}
