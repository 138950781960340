import { createStore } from 'zustand'
import { useEcProps } from '@toasttab/ec-banquet-props'

//#region service definition

type i18n = ReturnType<typeof useEcProps>['i18n']

//defines the props that the provider requires
export interface LanguageServiceProps {
  i18n: i18n
}

//defines the internal state of the store / service
export interface LanguageServiceFields {
  language: string
}

//defines the methods and computed fields (computed fields are just functions that read state)
export interface LanguageServiceMethods {
  switchLanguage: () => Promise<void>
  isSpanish: () => boolean
}

//the full service definition
export interface LanguageService
  extends LanguageServiceMethods,
    LanguageServiceFields,
    LanguageServiceProps {}

//#endregion

//service implementation
export function createLanguageService(initProps: LanguageServiceProps) {
  return createStore<LanguageService>()((set, get) => ({
    ...initProps,
    language: 'en',
    async switchLanguage() {
      const { i18n, isSpanish } = get()
      const languageToSwitchTo = isSpanish() ? 'en-US' : 'es'

      //optimistic update
      set({ language: languageToSwitchTo })

      const response = await fetch('/CompanyCode/ChangeLanguage', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ language: languageToSwitchTo })
      })
      if (response.ok) {
        await i18n.changeLanguage(languageToSwitchTo).catch(() => {
          console.warn('unable to change language')
          set({ language: i18n.language })
        })
      } else {
        console.warn('unable to change language')
        set({ language: i18n.language })
      }
    },
    isSpanish() {
      return get().language === 'es'
    }
  }))
}
