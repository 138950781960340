import { useMutation } from '@tanstack/react-query'
import { loginValidate } from '../helpers'

const useLoginValidateMutation = () => {
  const mutation = useMutation({
    mutationFn: async (variables: {
      email: string
      isEmailSingleUser: boolean
      companyCode?: string
      password: string
      reCaptchaToken?: string | null
    }) => {
      return loginValidate(variables)
    }
  })

  return mutation
}

export default useLoginValidateMutation
