import { InfoAlertLink } from '../../../components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export function ForgotCompanyCodeLink() {
  const { t } = useTranslation('login')

  return (
    <InfoAlertLink
      testId={'forgot-company-code'}
      trackId={'forgot-company-code'}
      linkText={t('companyCodeHelpPrompt')}
      infoContent={
        <>
          <b>{t('emailMultipleRestaurants')}</b>
          {'\n'}
          {t('dontRememberContactManager')}
        </>
      }
    />
  )
}
