import { ActionsBar, FormLayout } from '../../components'
import { Form, FormikProvider, useFormik } from 'formik'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { UsernameInput } from '../../components/UsernameInput'
import { BackToLogin } from '../BackToLogin'
import { NextSubmitButton } from '../../components/NextSubmitButton'

export type UsernameFormData = {
  username: string
}

export function UsernameForm(props: {
  username?: string
  onSubmit: (data: UsernameFormData) => Promise<void>
}) {
  const { username, onSubmit } = props
  const { t } = useTranslation('login')

  const formik = useFormik<UsernameFormData>({
    initialValues: {
      username: username || ''
    },
    validationSchema: Yup.object({
      username: Yup.string().required(t('email_required'))
    }),
    onSubmit: onSubmit
  })

  return (
    <FormLayout title={t('resetPassword')} subtitle={t('enterUsernameOrEmail')}>
      <FormikProvider value={formik}>
        <Form>
          <UsernameInput
            username={username}
            autoFocus={true}
            disabled={formik.isSubmitting}
          />
          <ActionsBar left={<BackToLogin />}>
            <NextSubmitButton
              mode={formik.isSubmitting ? 'in-progress' : 'ready'}
            />
          </ActionsBar>
        </Form>
      </FormikProvider>
    </FormLayout>
  )
}
