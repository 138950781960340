import { useRedirectToUrl } from '../../hooks'
import { InProgress } from '../InProgress'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { useEffect } from 'react'

export const LoggingOut = () => {
  const { redirectToUrl } = useRedirectToUrl()
  const { t } = useTranslation('login')

  useEffect(() => {
    redirectToUrl('/logout.aspx')
  }, [])

  return <InProgress title={t('loggingOut')} />
}
