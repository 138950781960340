import * as React from 'react'
import { TextToggleSwitch } from '@toasttab/buffet-pui-toggle-switches'
import { useLanguageService } from '@local/services'

export const LanguageSwitcher = () => {
  const isSpanish = useLanguageService((s) => s.isSpanish())
  const switchLanguage = useLanguageService((s) => s.switchLanguage)

  return (
    <div className='flex justify-center mb-4'>
      <div className='w-56'>
        <TextToggleSwitch
          name='language'
          labels={['English', 'Español']}
          isActive={isSpanish}
          onChange={switchLanguage}
        />
      </div>
    </div>
  )
}
