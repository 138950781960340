import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useEcTitle } from '@toasttab/ec-title'
import { useUserCompanyStatusMutation } from '../../data'
import { CompanyCodeForm, CompanyCodeFormData } from './CompanyCodeForm'
import { useResetPasswordDispatch } from '../ResetPasswordProvider'

export function CompanyCode(props: { username: string; companyCode?: string }) {
  const dispatch = useResetPasswordDispatch()
  const { username, companyCode } = props
  const { t } = useTranslation('login')

  useEcTitle(t('companyCode'))

  const { mutateAsync: userLinkingStatus, reset: resetUserLinkingStatus } =
    useUserCompanyStatusMutation()

  const onSubmit = useMemo(
    () => async (data: CompanyCodeFormData) => {
      resetUserLinkingStatus()

      const companyCode = data.companyCode.trim()

      const { status, loginNonce } = await userLinkingStatus({
        email: username,
        companyCode: companyCode
      })

      if (!loginNonce) {
        throw new Error('Login nonce was not found')
      }

      dispatch({
        type: 'company-code-entered',
        companyCode: companyCode,
        loginNonce: loginNonce,
        status: status
      })
    },
    [username, companyCode, dispatch, userLinkingStatus, resetUserLinkingStatus]
  )

  return (
    <CompanyCodeForm
      username={username}
      companyCode={companyCode}
      dispatch={dispatch}
      onSubmit={onSubmit}
    />
  )
}
