import { FormLayout } from '../../../components'
import { Form, FormikProvider, useFormik } from 'formik'
import * as React from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { CompanyCodeInput } from '../../../components/CompanyCodeInput'
import { PasswordInput } from '../../../components/PasswordInput'
import { MfaTokenInput } from '../../../components/MfaTokenInput'
import { LoginDispatch } from '../reducer'
import { FormActionsBar } from '../FormActionsBar'
import { UsernameInput } from '../../../components/UsernameInput'

export type MfaTokenFormData = {
  token: string
}

export function MfaTokenForm(props: {
  username: string
  companyCode?: string
  password: string
  error?: string
  dispatch: LoginDispatch
  onSubmit: (data: MfaTokenFormData) => Promise<void>
}) {
  const { username, companyCode, password, error, dispatch, onSubmit } = props
  const { t } = useTranslation('login')

  const formik = useFormik<MfaTokenFormData>({
    initialValues: {
      token: ''
    },
    validationSchema: Yup.object({
      token: Yup.string().required(t('token_required'))
    }),
    onSubmit: onSubmit
  })

  return (
    <FormLayout title={t('token')} subtitle={t('enterToken')} error={error}>
      <FormikProvider value={formik}>
        <Form>
          <UsernameInput username={username} disabled={true} />
          {companyCode && (
            <CompanyCodeInput companyCode={companyCode} disabled={true} />
          )}
          <PasswordInput password={password} disabled={true} />
          <MfaTokenInput autoFocus={true} disabled={formik.isSubmitting} />
          <FormActionsBar
            showBackButton={true}
            isInProgress={formik.isSubmitting}
          />
        </Form>
      </FormikProvider>
    </FormLayout>
  )
}
