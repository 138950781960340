import * as React from 'react'
import { ReactNode } from 'react'
import cx from 'classnames'

const ActionsBar = (props: { left?: ReactNode; children: ReactNode }) => {
  const { left, children } = props

  return (
    <div className={cx('flex justify-between items-center', 'pt-4 pb-2')}>
      <div className={cx('flex justify-start items-center', 'gap-4')}>
        {left}
      </div>
      <div className='flex justify-end gap-4 items-center'>{children}</div>
    </div>
  )
}

export default ActionsBar
