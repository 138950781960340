type LoginValidateApiResponse = {
  hasError: boolean
  obj: {
    userId: -1 | -4 | -7 | -8 | -11 | -97 | -98 | -99 | null
    url?: string
  } | null
  url?: string | null
}

export type LoginValidateResponseType =
  | 'success'
  | 'token-required'
  | 'password-expired'
  | 'force-reset-password'
  | 'unauthorized'
  | 'error'
  | 'ip-address-restriction'
  | 'rate-limit'

export type LoginValidateResponse = {
  type: LoginValidateResponseType
  url?: string
}

const loginValidate = async (args: {
  email: string
  isEmailSingleUser: boolean
  password: string
  companyCode?: string
  reCaptchaToken?: string | null
}): Promise<LoginValidateResponse> => {
  const {
    email, //
    password,
    companyCode,
    isEmailSingleUser,
    reCaptchaToken
  } = args

  type Payload = {
    username: string
    isSingleUser: boolean
    password: string
    isPublic: boolean
    reCaptchaToken?: string
  }

  const payload: Payload = {
    username: email,
    isSingleUser: isEmailSingleUser,
    password: password,
    isPublic: true
  }

  if (reCaptchaToken) {
    payload.reCaptchaToken = reCaptchaToken
  }

  const resp = await fetch(
    `/Login/${companyCode?.toLowerCase() || 'toast'}/Validate`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }
  )

  if (resp.status === 429) {
    return { type: 'rate-limit' }
  } else if (resp.ok) {
    const json = await resp.json()
    const parsed = json as LoginValidateApiResponse

    const { obj, url } = parsed

    if (obj?.userId) {
      switch (obj?.userId) {
        case -1:
          return { type: 'password-expired' }
        case -7:
          return { type: 'force-reset-password' }
        case -8:
          return { type: 'ip-address-restriction' }
        case -98:
        case -99:
          return { type: 'token-required' }
        case -97:
          console.warn('Google authenticator not supported')
          break
      }
    } else if (url) {
      return { type: 'success', url }
    }
  } else {
    return { type: 'error' }
  }

  return { type: 'unauthorized' }
}

export default loginValidate
