// controlled in https://console.cloud.google.com/security/recaptcha?project=payroll-login-403113

const SITE_KEYS = {
  PROD: '6LdLgOAoAAAAAAsQG2qoUbQOBiZ1e5NtmT6vwn0W',
  PRE_PROD: '6LdZst8oAAAAAMNsk0bgq_ucvlREkuBXgcrAWu9m',
  DEV: '6Le-3d8oAAAAAEECN8__E_zJf-U58ZPd1_xxTG47',
  // from here https://developers.google.com/recaptcha/docs/faq#id-like-to-run-automated-tests-with-recaptcha.-what-should-i-do
  TEST: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
} as const

const ENVS_CONFIG = [
  {
    urlMatches: [],
    siteKey: SITE_KEYS.PROD
  },
  {
    urlMatches: ['web.qastratex', 'preprod.eng.toasttab.com'],
    siteKey: SITE_KEYS.PRE_PROD
  },
  {
    urlMatches: ['web.devstratex'],
    siteKey: SITE_KEYS.DEV
  },
  {
    urlMatches: ['localhost', 'http://', 'payroll.eng.toastteam.com'],
    siteKey: SITE_KEYS.DEV
  }
] as const

const PROD_CONFIG = ENVS_CONFIG[0]

const getV2SiteKey = (url: string) => {
  const matchFromUrl = ENVS_CONFIG.find((config) => {
    return config.urlMatches
      .map((str) => str) // TS being mad about readonly arrays
      .some((urlMatch) => url.indexOf(urlMatch) > -1)
  })

  const match = matchFromUrl || PROD_CONFIG

  return match.siteKey
}

const V2_SITE_KEY = getV2SiteKey(window.location.href)

export { V2_SITE_KEY }
