import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useEcTitle } from '@toasttab/ec-title'
import { CompanyCodeForm, CompanyCodeFormData } from './CompanyCodeForm'
import { useUserCompanyStatusMutation } from '../../../data'
import { useLoginDispatch } from '../LoginProvider'

export function CompanyCode(props: { username: string; companyCode?: string }) {
  const dispatch = useLoginDispatch()
  const { username, companyCode } = props
  const { t } = useTranslation('login')
  const {
    mutateAsync: userCompanyLinkingStatus,
    reset: resetUserCompanyLinkingStatus
  } = useUserCompanyStatusMutation()

  useEcTitle(t('companyCode'))

  const onSubmit = useMemo(
    () => async (data: CompanyCodeFormData) => {
      resetUserCompanyLinkingStatus()

      const companyCode = data.companyCode.trim().toLowerCase()

      const { status, loginNonce, isCaptchaRequired } =
        await userCompanyLinkingStatus({
          email: username,
          companyCode: companyCode
        })

      if (!loginNonce) {
        throw new Error('Login nonce was not specified')
      }

      dispatch({
        type: 'company-code-entered',
        companyCode: companyCode,
        status: status,
        loginNonce: loginNonce,
        isCaptchaRequired: isCaptchaRequired
      })
    },
    [
      username,
      companyCode,
      userCompanyLinkingStatus,
      resetUserCompanyLinkingStatus,
      dispatch
    ]
  )

  return (
    <CompanyCodeForm
      dispatch={dispatch}
      username={username}
      companyCode={companyCode}
      onSubmit={onSubmit}
    />
  )
}
