import * as React from 'react'
import { ReactNode } from 'react'

export const ExtraBar = (props: { children: ReactNode }) => {
  const { children } = props

  return <div className='py-1'>{children}</div>
}

export default ExtraBar
