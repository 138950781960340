import cookie from 'js-cookie'

const setSavedCompanyCode = (code: string | undefined | null) => {
  if (code) {
    cookie.set('companyCode', code, { expires: 1000 })
  } else {
    cookie.remove('companyCode')
  }
}

const getSavedCompanyCode = () => {
  return cookie.get('companyCode') || undefined
}

export { setSavedCompanyCode, getSavedCompanyCode }
