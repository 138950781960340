import { Input } from './index'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export function CompanyCodeInput(props: {
  companyCode?: string
  autoFocus: boolean
  disabled: boolean
}) {
  const { companyCode, autoFocus, disabled } = props
  const { t } = useTranslation('login')

  return (
    <Input
      name='companyCode'
      data-testid='companyCode'
      label={t('companyCode')}
      autoFocus={autoFocus}
      disabled={disabled}
      value={companyCode}
    />
  )
}

CompanyCodeInput.defaultProps = {
  autoFocus: false,
  disabled: false
}
