import { Form, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { ActionsBar, FormLayout } from '../../components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { UsernameInput } from '../../components/UsernameInput'
import { CompanyCodeInput } from '../../components/CompanyCodeInput'
import { ResetPasswordDispatch } from '../reducer'
import { BackButton } from '../BackButton'
import { NextSubmitButton } from '../../components/NextSubmitButton'

export type CompanyCodeFormData = {
  companyCode: string
}

export function CompanyCodeForm(props: {
  username: string
  companyCode?: string
  dispatch: ResetPasswordDispatch
  onSubmit: (data: CompanyCodeFormData) => Promise<void>
}) {
  const { username, companyCode, onSubmit, dispatch } = props
  const { t } = useTranslation('login')

  const formik = useFormik<CompanyCodeFormData>({
    initialValues: {
      companyCode: companyCode || ''
    },
    validationSchema: Yup.object({
      companyCode: Yup.string().required(t('companyCode_required'))
    }),
    onSubmit: onSubmit
  })

  return (
    <FormLayout title={t('resetPassword')} subtitle={t('enterCompanyCode')}>
      <FormikProvider value={formik}>
        <Form>
          <UsernameInput username={username} disabled={true} />
          <CompanyCodeInput companyCode={companyCode} autoFocus={true} />

          <ActionsBar left={<BackButton disabled={formik.isSubmitting} />}>
            <NextSubmitButton
              mode={formik.isSubmitting ? 'in-progress' : 'ready'}
            >
              {t('send')}
            </NextSubmitButton>
          </ActionsBar>
        </Form>
      </FormikProvider>
    </FormLayout>
  )
}
