import { redirectTo } from '../helpers'
import { useState } from 'react'

const useRedirectToUrl = () => {
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false)

  const redirectToUrl = (url: string) => {
    setIsRedirecting(true)

    redirectTo(url)
  }

  return { redirectToUrl, isRedirecting }
}

export default useRedirectToUrl
