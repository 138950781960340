import type { Translations } from './en'

const es: Translations = {
  error: {
    companyCodeNotFound: 'Código de empresa no encontrado',
    customerAccessNotAllowed:
      'El código de la empresa en el que está intentando iniciar sesión no está activo en Toast Payroll. Si es un empleado, comuníquese con el gerente de su restaurante para conocer los próximos pasos.',
    expiredPassword:
      'Tu contraseña ha expirado. por favor, cambie su contraseña',
    expiredPasswordLink: 'Restablecimiento de contraseña',
    generic: 'Ocurrió un error',
    invalidToken: 'El token que ingresaste es incorrecto. Inténtalo de nuevo',
    ipAddressRestriction: 'Hay un problema con tu cuenta',
    legacyUsernameProvided:
      'Inicie sesión con su dirección de correo electrónico',
    loginNotAvailableTitle: 'Iniciar sesión no disponible',
    rateLimit: 'Vuelve a intentarlo en unos minutos',
    rateLimitTitle: 'Algo salió mal',
    resetPassword:
      'Se produjo un error al enviar una solicitud por correo electrónico para restablecer la contraseña. Por favor contacte al soporte.',
    resetPasswordUnknown: 'Algo salió mal. Por favor contacte al soporte.',
    singleSignOnEnabled:
      'Error de restablecimiento de contraseña. La empresa tiene habilitado el inicio de sesión único. Comuníquese con su gerente.',
    translationFailed: 'No se pudo guardar la configuración de traducción',
    unauthorized: 'Código de empresa, usuario o contraseña incorrectos',
    unknown: 'Algo salió mal'
  },
  success: {
    passwordReset:
      'Se ha enviado un correo electrónico a su dirección de correo electrónico registrado con instrucciones. Si no recibe este correo electrónico dentro de 2 horas, verifique sus carpetas de SPAM e intente restablecer su contraseña nuevamente.',
    passwordSuccess: 'La contraseña ha sido restablecida exitosamente.'
  },
  back: 'Atrás',
  cantAccessEmail: '¿No puedes acceder a tu correo electrónico?',
  checkingExistingSessions: 'Comprobación de sesiones existentes',
  companyCode: 'Código de empresa',
  companyCodeHelpPrompt: '¿Qué es un Código de empresa?',
  companyCode_required: 'El código de la empresa es obligatorio',
  contactManager:
    'Comuníquese con su gerente para encontrar el código de su empresa.',
  contactManagerEmail:
    'Comuníquese con su gerente para establecer una dirección de correo electrónico válida para su cuenta de Toast Payroll ',
  continueToPayroll: 'Continuar con Nómina',
  customerId_required: 'Se requiere ID del cliente',
  customerUuid_required: 'Se requiere el UUID del cliente',
  dontRememberContactManager:
    'Si no recuerda su código de empresa, comuníquese con su gerente.',
  dontRememberYourPassword: '¿Has olvidado tu contraseña?',
  dontRememberYourPasswordInstructions:
    'Al reiniciar, se enviará un token de seguridad y más instrucciones a su dirección de correo electrónico registrado',
  email: 'Email',
  emailMultipleRestaurants: 'Tu email está asociado a más de un restaurante',
  emailUsername: 'Correo Electronico o Nombre de Usuario',
  email_required: 'Email o Nombre de Usuario es requerido',
  enterCompanyCode: 'Introduzca el código de su empresa',
  enterPassword: 'Ingresa tu contraseña',
  enterToken:
    'Ingrese el token de inicio de sesión que le enviamos a su correo electrónico',
  enterUsernameOrEmail:
    'Ingrese su correo electrónico o nombre de usuario para iniciar sesión',
  forceResetPassword: 'Restablecer su contraseña',
  forceResetPasswordInstructions:
    'Solicite un restablecimiento de contraseña y le enviaremos un correo electrónico para ayudarlo a volver a la normalidad.',
  forgotCompanyCode: '¿No recuerda el código de su empresa?',
  forgotPassword: 'Restablecer la contraseña',
  loggedInAs: 'Ha iniciado sesión como "{{username}}"',
  loggingIn: 'Iniciando sesión',
  loggingInViaToast: 'Iniciar sesión con Toast',
  loggingOut: 'Cierre de sesión',
  next: 'Siguiente',
  password: 'Contraseña',
  passwordReset: 'Restablecimiento de contraseña',
  password_required: 'Se requiere contraseña',
  recaptcha_required: 'Se requiere ReCaptcha',
  resend: 'Reenviar',
  resendEmail: 'Reenviar email',
  resetEmailInstructions: `Se le ha enviado por correo electrónico un enlace para restablecer su contraseña. Si no puede ver este correo electrónico, revise su carpeta de correo no deseado o intente restablecer su contraseña nuevamente.`,
  resetEmailSent: `Te enviamos un correo electrónico`,
  resetPassword: 'Restablecer la contraseña',
  resetPasswordPrompt:
    'Te enviaremos un correo electrónico con un enlace para restablecer tu contraseña',
  resetPasswordWithToast: 'Redirigirte a Toast',
  resettingPassword: 'Restablecimiento de contraseña',
  securityUpdate: 'Actualización de seguridad',
  securityUpdateSubtitle:
    'Toast ha actualizado sus procedimientos de seguridad. Enviamos un vínculo para restablecer la contraseña por correo electrónico a {{email}}. Si no puede encontrarlo en su bandeja de entrada, verifique su carpeta de correo no deseado.',
  send: 'Enviar',
  signIn: 'Acceso',
  signOut: 'Cerrar sesión',
  switchToEmailToken: 'Cambiar a token del correo electrónico',
  token: 'Token',
  token_required: 'Se requiere token',
  troubleLoggingIn: '¿Problemas para iniciar sesión?',
  twoStepVerification: 'Verificación De Pos Pasos',
  userUuid_required: 'Se requiere el UUID del usuario',
  username: 'Nombre de usuario',
  welcome: 'Bienvenido de nuevo'
}

export { es }
