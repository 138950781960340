import { useEcTitle } from '@toasttab/ec-title'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { useMemo } from 'react'
import { useUserStatusMutation } from '../../../data'
import { UsernameForm, UsernameFormData } from './UsernameForm'
import { useLoginDispatch } from '../LoginProvider'

export const Username = (props: { username?: string }) => {
  const dispatch = useLoginDispatch()
  const { username } = props
  const { mutateAsync: userStatus, reset: resetUserStatus } =
    useUserStatusMutation()
  const { t } = useTranslation('login')

  useEcTitle(t('username'))

  const onSubmit = useMemo(
    () => async (data: UsernameFormData) => {
      resetUserStatus()

      const username = data.username.trim()

      const { status, loginNonce, isCaptchaRequired } = await userStatus({
        email: username
      })

      if (!loginNonce) {
        throw new Error('Login nonce not found')
      }

      dispatch({
        type: 'username-entered',
        status: status,
        username: username,
        loginNonce: loginNonce,
        isCaptchaRequired: isCaptchaRequired
      })
    },
    [username, dispatch, userStatus, resetUserStatus]
  )

  return <UsernameForm username={username} onSubmit={onSubmit} />
}
