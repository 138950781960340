import { useTranslation } from 'react-i18next'
import * as React from 'react'

const languageToHelpUrl: Record<string, string> = {
    en: 'https://central.toasttab.com/s/article/Toast-Payroll-Logging-In',
    es: 'https://central.toasttab.com/s/article/es-US-Toast-Payroll-Logging-In'
}

export const TroubleLoggingIn = () => {
    const {t, i18n} = useTranslation()
    const url = languageToHelpUrl[i18n.language] || languageToHelpUrl.en
    
    return (
        <div className='flex flex-row w-full justify-end p-1'>
            <a
                className='self-end text-link p-1'
                href={url}
                target='_blank'
                rel='noopener noreferrer'
                data-testid='trouble-logging-in'
                data-toast-track-id='trouble-logging-in'
            >
                {t('troubleLoggingIn')}
            </a>
        </div>
    )
}
