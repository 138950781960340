import { ResetPasswordContextProvider } from './ResetPasswordProvider'
import { ResetPassword } from './ResetPassword'
import * as React from 'react'

export function ResetPasswordApp(props: {
  username?: string
  companyCode?: string
}) {
  return (
    <ResetPasswordContextProvider {...props}>
      <ResetPassword />
    </ResetPasswordContextProvider>
  )
}
