import { useState, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { isBlockedReturnUrl } from '../helpers'

const decode = (str: string | undefined | null) => {
  if (!str) return null
  try {
    return decodeURIComponent(str)
  } catch {
    return null
  }
}

const parseReturnUrl = (raw: string | undefined | null): string | undefined => {
  try {
    const url = decode(raw)

    if (!url) return undefined

    const pathname = url.split('?')[0].toLowerCase()

    if (isBlockedReturnUrl(pathname)) {
      return undefined
    }

    return url
  } catch {
    return undefined
  }
}

function normalizeParams(searchParams: URLSearchParams): URLSearchParams {
  const normalizedParams = new URLSearchParams()
  for (const [name, value] of searchParams) {
    normalizedParams.append(name.toLowerCase(), value)
  }
  return normalizedParams
}

const useSignInSearchParams = () => {
  const [searchParams] = useSearchParams()

  const normalizedParams = normalizeParams(searchParams)

  const [rawReturnUrl] = useState<string | undefined>(() => {
    return normalizedParams.get('returnurl') || undefined
  })

  const [isReauth] = useState<boolean>(() => {
    return normalizedParams.get('reauth') === 'true'
  })

  const decodedReturnUrl = useMemo(() => {
    return parseReturnUrl(rawReturnUrl)
  }, [rawReturnUrl])

  return {
    returnUrl: decodedReturnUrl,
    isReauth
  }
}

export { parseReturnUrl, useSignInSearchParams }
