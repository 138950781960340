import * as React from 'react'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { StrictTypedTypePolicies } from './generated/helpers'
import { ApolloProvider } from '@apollo/client'

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {} as StrictTypedTypePolicies
  }),
  uri: '/login/graph'
})

export function Apollo({ children }: React.PropsWithChildren<{}>) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
