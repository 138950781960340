import { useEcProps } from '@toasttab/ec-banquet-props'
import { createAuth0Url } from '../helpers'

import useRedirectToUrl from './useRedirectToUrl'

type Variables = {
  email: string
  loginNonce: string
  companyCode?: string
  returnUrl?: string
  isReauth?: boolean
}

const useRedirectToAuth0 = () => {
  const { auth0BaseUrl, auth0ClientId } = useEcProps()

  const { redirectToUrl, isRedirecting } = useRedirectToUrl()

  const redirectToAuth0 = (variables: Variables) => {
    const { email, loginNonce, companyCode, returnUrl, isReauth } = variables

    const authZeroUrl = createAuth0Url({
      email,
      loginNonce,
      companyCode,
      auth0BaseUrl,
      auth0ClientId,
      returnUrl,
      isReauth
    })

    redirectToUrl(authZeroUrl)
  }

  return { redirectToAuth0, isRedirecting }
}

export default useRedirectToAuth0
