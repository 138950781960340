import { Button } from '@toasttab/buffet-pui-buttons'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useLoginDispatch } from './LoginProvider'

export function BackButton(props: { disabled: boolean }) {
  const dispatch = useLoginDispatch()
  const { disabled } = props
  const { t } = useTranslation('login')

  return (
    <Button
      variant='text-link'
      testId='back'
      onClick={() => {
        dispatch({ type: 'back' })
      }}
      disabled={disabled}
    >
      {t('back')}
    </Button>
  )
}

BackButton.defaultProps = {
  disabled: false
}
