import * as React from 'react'
import { PropsWithChildren } from 'react'
import { LoginState } from './state'
import { LoginDispatch } from './reducer'

export const LoginStateContext = React.createContext<LoginState | undefined>(
  undefined
)

export const LoginDispatchContext = React.createContext<
  LoginDispatch | undefined
>(undefined)

export function LoginContextProvider({
  state,
  dispatch,
  children
}: { state: LoginState; dispatch: LoginDispatch } & PropsWithChildren) {
  return (
    <LoginStateContext.Provider value={state}>
      <LoginDispatchContext.Provider value={dispatch}>
        {children}
      </LoginDispatchContext.Provider>
    </LoginStateContext.Provider>
  )
}

export function useLoginState<T extends LoginState>(): T {
  const state = React.useContext(LoginStateContext)
  if (state === undefined) {
    throw new Error('useLoginState must be used within a LoginContextProvider')
  }
  return state as T
}

export function useLoginDispatch(): LoginDispatch {
  const dispatch = React.useContext(LoginDispatchContext)
  if (dispatch === undefined) {
    throw new Error(
      'useSessionDispatch must be used within a LoginContextProvider'
    )
  }
  return dispatch
}
