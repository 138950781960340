import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useEcTitle } from '@toasttab/ec-title'
import { Form, FormikProvider, useFormik } from 'formik'
import { ActionsBar, FormLayout } from '../../../components'
import { UsernameInput } from '../../../components/UsernameInput'
import { CompanyCodeInput } from '../../../components/CompanyCodeInput'
import { NextSubmitButton } from '../../../components/NextSubmitButton'
import { BackButton } from '../BackButton'
import { useLoginDispatch } from '../LoginProvider'

type ResetFromPayrollFormData = {
  username: string
  companyCode?: string
}

export function ResetPasswordForm(props: {
  username: string
  companyCode?: string
}) {
  const dispatch = useLoginDispatch()
  const { username, companyCode } = props
  const { t } = useTranslation('login')

  useEcTitle(t('resetPassword'))

  const formik = useFormik<ResetFromPayrollFormData>({
    initialValues: {
      username: username,
      companyCode: companyCode || ''
    },
    onSubmit: () => {
      dispatch({
        type: 'reset-password'
      })
    }
  })

  return (
    <FormLayout title={t('resetPassword')} subtitle={t('resetPasswordPrompt')}>
      <FormikProvider value={formik}>
        <Form>
          <UsernameInput username={username} disabled={true} />
          {companyCode && (
            <CompanyCodeInput companyCode={companyCode} disabled={true} />
          )}
          <ActionsBar left={<BackButton disabled={formik.isSubmitting} />}>
            <NextSubmitButton
              mode={formik.isSubmitting ? 'in-progress' : 'ready'}
            >
              {t('send')}
            </NextSubmitButton>
          </ActionsBar>
        </Form>
      </FormikProvider>
    </FormLayout>
  )
}
