import * as React from 'react'

//this is a simple utility hook to create a store unless it's already made
export function useCreateSingletonService<TService>(create: () => TService) {
  const serviceRef = React.useRef<TService>()

  //make sure that if we don't have an instance of the store that we actually get one.
  if (!serviceRef.current) {
    serviceRef.current = create()
  }

  return serviceRef.current
}
