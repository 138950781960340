import { ExtraBar, FormLayout } from '../../../components'
import { Form, FormikProvider, useFormik } from 'formik'
import { ReCaptcha } from '../../../captcha/v2'
import * as React from 'react'
import { useMemo } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { CompanyCodeInput } from '../../../components/CompanyCodeInput'
import { PasswordInput } from '../../../components/PasswordInput'
import { ResetPasswordButton } from '../resetPassword/ResetPasswordButton'
import { LoginDispatch } from '../reducer'
import { FormActionsBar } from '../FormActionsBar'
import { HelperText } from '@toasttab/buffet-pui-text-base'
import { UsernameInput } from '../../../components/UsernameInput'

export type PasswordFormData = {
  password: string
  reCaptchaToken: string
}

export function PasswordForm(props: {
  username: string
  companyCode?: string
  isCaptchaRequired: boolean
  error?: string
  dispatch: LoginDispatch
  onSubmit: (data: PasswordFormData) => Promise<void>
}) {
  const {
    username,
    companyCode,
    error,
    dispatch,
    isCaptchaRequired,
    onSubmit
  } = props

  const { t } = useTranslation('login')
  const formik = useFormik<PasswordFormData>({
    initialValues: {
      password: '',
      reCaptchaToken: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().required(t('password_required')!),
      reCaptchaToken: isCaptchaRequired
        ? Yup.string().required(t('recaptcha_required')!)
        : Yup.string().default('')
    }),
    onSubmit: onSubmit
  })

  const reCaptchaError = useMemo(
    () => formik.errors.reCaptchaToken,
    [formik.errors.reCaptchaToken]
  )

  return (
    <FormLayout
      title={t('welcome')}
      subtitle={t('enterPassword')}
      error={error}
    >
      <FormikProvider value={formik}>
        <Form>
          <UsernameInput username={username} disabled={true} />
          {companyCode && (
            <CompanyCodeInput companyCode={companyCode} disabled={true} />
          )}
          <PasswordInput autoFocus={true} disabled={formik.isSubmitting} />
          {isCaptchaRequired && (
            <>
              <ReCaptcha
                onToken={(token) => {
                  formik.setFieldValue(
                    'reCaptchaToken',
                    token ? token : undefined
                  )
                }}
              />
              {reCaptchaError && (
                <HelperText invalid={true} errorText={reCaptchaError} />
              )}
            </>
          )}
          <ExtraBar>
            <ResetPasswordButton
              onClick={() => {
                dispatch({ type: 'reset-password' })
              }}
            />
          </ExtraBar>
          <FormActionsBar
            showBackButton={true}
            isInProgress={formik.isSubmitting}
          />
        </Form>
      </FormikProvider>
    </FormLayout>
  )
}
