import { Login } from './Login'
import { LoginContextProvider } from './LoginProvider'
import * as React from 'react'
import { useSignInSearchParams } from '../../hooks'
import { useReducer } from 'react'
import { loginReducer } from './reducer'
import { getSavedCompanyCode } from '../../helpers'

export const LoginApp = (props: { isReauth: boolean; returnUrl?: string }) => {
  const { isReauth, returnUrl } = props
  const [state, dispatch] = useReducer(loginReducer, {
    type: 'wait-for-username',
    companyCode: getSavedCompanyCode(),
    returnUrl: returnUrl,
    isReauth: isReauth
  })

  return (
    <LoginContextProvider state={state} dispatch={dispatch}>
      <Login />
    </LoginContextProvider>
  )
}
