import { useTranslation } from 'react-i18next'
import { Form, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { FormLayout } from '../../../components'
import * as React from 'react'
import { FormActionsBar } from '../FormActionsBar'
import { UsernameInput } from '../../../components/UsernameInput'

export type UsernameFormData = {
  username: string
}

export function UsernameForm(props: {
  username?: string
  onSubmit: (data: UsernameFormData) => Promise<void>
}) {
  const { onSubmit, username } = props
  const { t } = useTranslation('login')

  const formik = useFormik<UsernameFormData>({
    initialValues: {
      username: username || ''
    },
    validationSchema: Yup.object({
      username: Yup.string().required(t('email_required'))
    }),
    onSubmit: onSubmit
  })

  return (
    <FormLayout title={t('welcome')} subtitle={t('enterUsernameOrEmail')}>
      <FormikProvider value={formik}>
        <Form>
          <UsernameInput
            username={username}
            autoFocus={true}
            disabled={formik.isSubmitting}
          />
          <FormActionsBar isInProgress={formik.isSubmitting} />
        </Form>
      </FormikProvider>
    </FormLayout>
  )
}
