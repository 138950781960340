import { useTranslation } from 'react-i18next'
import { useEcTitle } from '@toasttab/ec-title'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { MfaTokenForm, MfaTokenFormData } from './MfaTokenForm'
import { useValidateMfaTokenMutation } from '../../../data'
import { useLoginDispatch } from '../LoginProvider'

export const MfaToken = (props: {
  username: string
  companyCode?: string
  password: string
}) => {
  const dispatch = useLoginDispatch()
  const { username, companyCode, password } = props
  const [error, setError] = useState<string | undefined>(undefined)

  const { t } = useTranslation('login')
  const { mutateAsync: validateMfaToken, reset: resetValidateMfaToken } =
    useValidateMfaTokenMutation()

  useEcTitle(t('token'))

  const onSubmit = useMemo(
    () => async (data: MfaTokenFormData) => {
      setError(undefined)
      resetValidateMfaToken()

      const { hasError, obj, url } = await validateMfaToken({
        email: username,
        isEmailSingleUser: companyCode === undefined,
        companyCode,
        password,
        token: data.token.trim()
      })

      if (hasError) {
        setError(t('error.invalidToken'))
      } else {
        dispatch({
          type: 'mfa-token-validated',
          url: obj?.url ?? url
        })
      }
    },
    [
      username,
      companyCode,
      password,
      dispatch,
      validateMfaToken,
      resetValidateMfaToken,
      setError
    ]
  )

  return (
    <MfaTokenForm
      error={error}
      dispatch={dispatch}
      username={username}
      companyCode={companyCode}
      password={password}
      onSubmit={onSubmit}
    />
  )
}
