import { Button } from '@toasttab/buffet-pui-buttons'
import * as React from 'react'

export function BackToLogin() {
  return (
    <Button as='a' variant='secondary' href='/sign-in'>
      Back to Login
    </Button>
  )
}
