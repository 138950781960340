import * as React from 'react'
import { PropsWithChildren } from 'react'
import { SessionState } from './state'
import { SessionDispatch } from './reducer'

export const SessionStateContext = React.createContext<
  SessionState | undefined
>(undefined)

export const SessionDispatchContext = React.createContext<
  SessionDispatch | undefined
>(undefined)

export function SessionContextProvider({
  children,
  state,
  dispatch
}: { state: SessionState; dispatch: SessionDispatch } & PropsWithChildren) {
  return (
    <SessionStateContext.Provider value={state}>
      <SessionDispatchContext.Provider value={dispatch}>
        {children}
      </SessionDispatchContext.Provider>
    </SessionStateContext.Provider>
  )
}

export function useSessionState<T extends SessionState>(): T {
  const state = React.useContext(SessionStateContext)
  if (state === undefined) {
    throw new Error(
      'useSessionStateProvider must be used within a SessionStateProvider'
    )
  }
  return state as T
}

export function useSessionDispatch(): SessionDispatch {
  const dispatch = React.useContext(SessionDispatchContext)
  if (dispatch === undefined) {
    throw new Error(
      'useSessionDispatchProvider must be used within a SessionContextProvider'
    )
  }
  return dispatch
}
