import * as React from 'react'
import { StoreApi, useStore } from 'zustand'
import { LanguageService, createLanguageService } from './service'
import { useCreateSingletonService } from '../utils/useCreateService'
import { useEcProps } from '@toasttab/ec-banquet-props'
import { I18nextProvider } from 'react-i18next'

import { en } from './locales/en'
import { es } from './locales/es'

//defines the context for the Sample service
const LanguageServiceContext =
  React.createContext<StoreApi<LanguageService> | null>(null)

export const LanguageServiceProvider = ({
  children
}: React.PropsWithChildren<{}>) => {
  const { i18n } = useEcProps()
  const service = useCreateSingletonService(() =>
    createLanguageService({ i18n })
  )

  i18n.addResourceBundle('en', 'login', en)
  i18n.addResourceBundle('es', 'login', es)

  return (
    <I18nextProvider i18n={i18n} defaultNS='login'>
      <LanguageServiceContext.Provider value={service}>
        {children}
      </LanguageServiceContext.Provider>
    </I18nextProvider>
  )
}

/**
  This is the actual hook to use
*/
export function useLanguageService<TSelectorResult>(
  selector: (state: LanguageService) => TSelectorResult,
  equalityFn?: (left: TSelectorResult, right: TSelectorResult) => boolean
) {
  const store = React.useContext(LanguageServiceContext)
  if (!store) throw new Error('Missing SampleProvide v2 in the tree')

  return useStore(store, selector, equalityFn)
}
