const getReCaptchaV3Script = () => {
  const script = document.createElement('script')

  script.src = `https://www.google.com/recaptcha/api.js`
  script.async = true
  script.defer = true

  return script
}

export { getReCaptchaV3Script }
