import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { ExtraBar, FormLayout } from '../../../components'
import { CompanyCodeInput } from '../../../components/CompanyCodeInput'
import { ForgotCompanyCodeLink } from './ForgotCompanyCodeLink'
import { LoginDispatch } from '../reducer'
import { FormActionsBar } from '../FormActionsBar'
import { UsernameInput } from '../../../components/UsernameInput'

export type CompanyCodeFormData = {
  companyCode: string
}

export function CompanyCodeForm(props: {
  username: string
  companyCode?: string
  dispatch: LoginDispatch
  onSubmit: (data: CompanyCodeFormData) => Promise<void>
}) {
  const { username, onSubmit, dispatch, companyCode } = props
  const { t } = useTranslation('login')

  const formik = useFormik<CompanyCodeFormData>({
    initialValues: {
      companyCode: companyCode || ''
    },
    validationSchema: Yup.object({
      companyCode: Yup.string().required(t('companyCode_required'))
    }),
    onSubmit: onSubmit
  })

  return (
    <FormLayout title={t('welcome')} subtitle={t('enterCompanyCode')}>
      <FormikProvider value={formik}>
        <Form>
          <UsernameInput username={username} disabled={true} />
          <CompanyCodeInput autoFocus={true} disabled={formik.isSubmitting} />
          <ExtraBar>
            <ForgotCompanyCodeLink />
          </ExtraBar>
          <FormActionsBar
            showBackButton={true}
            isInProgress={formik.isSubmitting}
          />
        </Form>
      </FormikProvider>
    </FormLayout>
  )
}
