type PayrollUser = {
  username: string
  companyCode: string
}
const fetchPayrollSession = async (): Promise<PayrollUser | undefined> => {
  try {
    const response = await fetch(`/me`, {
      credentials: 'same-origin'
    })
    if (!response.redirected) {
      const json = await response.json()
      if (json) {
        const { email, client } = json.user
        return {
          username: email,
          companyCode: client
        }
      }
    }
  } catch (e) {}
}

export { fetchPayrollSession, PayrollUser }
