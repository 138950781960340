import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { fetchPayrollSession } from '../../helpers'
import { InProgress } from '../InProgress'
import { useTranslation } from 'react-i18next'
import { useSessionDispatch } from './SessionProvider'

export const GetPayrollSession = () => {
  const dispatch = useSessionDispatch()
  const { t } = useTranslation('login')

  const getPayrollSession = useMemo(
    () => async () => {
      const user = await fetchPayrollSession()
      if (user === undefined) {
        dispatch({
          type: 'payroll-session-not-found'
        })
      } else {
        dispatch({
          type: 'payroll-session-found',
          user: user
        })
      }
    },
    [fetchPayrollSession, dispatch]
  )

  useEffect(() => {
    getPayrollSession()
  }, [getPayrollSession])

  return (
    <InProgress title={t('welcome')} subtitle={t('checkingExistingSessions')} />
  )
}
